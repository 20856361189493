import get from 'lodash/get';
import ComponentStore from 'components/data/ComponentStore';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Template from '../types/template.type';

/**
 * Get the data from the template.
 * @param path - The path to the data.
 * @param options - The options for the data.
 * @returns The data.
 */
export const getTemplateData = <Data>(path?: string, options?: { clone?: boolean }): Data => {
    const { clone = true } = options ?? {};

    const template = ComponentStore.get('TemplateDesigner') as Template;

    if (!path || path === '') {
        if (clone) return cloneDeep(template) as Data;
        return template as Data;
    }

    if (clone) return cloneDeep(get(template, path)) as Data;
    return get(template, path) as Data;
};
