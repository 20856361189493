import lottie from 'lottie-web';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import { TDzoomLevel } from 'types/event-emitter.type';
import ComponentStore from 'components/data/ComponentStore';
import arrangeItemsInGrid from 'helpers/arrangeItemsInGrid';
import { getTemplateData } from './data.helpers';
import Template, { State, TemplateData, View } from '../types/template.type';
import Layer from '../types/layer.type';
import { StylingHelpers } from './styling.helpers';
import { LayerPropertiesHelpers } from './layer-properties.helpers';
import cloneDeep from '../utils/cloneDeep';
import LayerProperties, { PredefinedAnimations, PredefinedAnimationsKey } from '../types/layerProperties.type';
import { LayerCalculationHelpers } from './layer-calculation.helpers';
import Format from '../types/format.type';
import { InfiniteViewerHelpers } from './infinite-viewer.helpers';
import {
    DEFAULT_FORMAT_GAP,
    FOOTER_HEIGHT_BIG,
    MAX_WIDTH_CANVAS_DISPLAY,
    MAX_WIDTH_CANVAS_IMAGE,
    MAX_WIDTH_CANVAS_PDF,
    MAX_WIDTH_CANVAS_VIDEO,
    PDF_FORMAT_GAP
} from '../constants';
import FormatHelpers from './format.helpers';
import { MAX_ZOOM } from '../constants';
import TemplateDesignerStore from '../data/template-designer-store';
import { TemplateVersionHelpers } from './template-version.helpers';
import { TimelineHelpers } from './timeline.helpers';
import {
    AnimationOptions,
    AnimationValue,
    OpacityAnimation,
    PositionAnimation,
    PredefinedAnimation,
    PredefinedAnimationValue,
    PredefinedPositionAnimation,
    PredefinedSetupScaleAnimation,
    RotationAnimation,
    ScaleAnimation
} from '../types/animation.type';
import FrameType from '../types/frameTypes.type';
import getPredefinedAnimationsSetup, { PredefinedAnimationSetup } from '../config/predefined-animations-setup';

class CanvasHelpers {
    static mediaTimeouts = {};
    static lottieTimeouts = {};
    static CANVAS_SCALE = 10;

    /**
     * Arrange the formats in the canvas.
     * @param formats - The formats to arrange.
     */
    static arrangeFormats = (formats?: Format[]): void => {
        if (formats === undefined) formats = getTemplateData<Template['formats']>('formats');

        let formatItems: Format[] = formats;

        const templateType = getTemplateData<TemplateData['type']>('templateData.type');
        const zoomLevel = InfiniteViewerHelpers.getZoomLevel();

        const maxWidth = (() => {
            const widestFormat = formats.reduce((width, format) => (format.width > width ? format.width : width), 0);
            const containerSize = InfiniteViewerHelpers.getContainerSize();

            const maxWidths = {
                displayAdDesigned: MAX_WIDTH_CANVAS_DISPLAY,
                dynamicImageDesigned: MAX_WIDTH_CANVAS_IMAGE,
                dynamicVideoDesigned: MAX_WIDTH_CANVAS_VIDEO,
                dynamicPDFDesigned: MAX_WIDTH_CANVAS_PDF
            };

            const templateTypeWidth = maxWidths[templateType];

            return Math.max(widestFormat, templateTypeWidth, containerSize.width);
        })();

        const gap =
            {
                dynamicPDFDesigned: PDF_FORMAT_GAP
            }[templateType] ?? DEFAULT_FORMAT_GAP;

        const formatsToArrange = formats.map((format) => ({
            width: format.width,
            height: format.height,
            itemKey: format.key,
            item: format
        }));

        const formatItemsNested = arrangeItemsInGrid({
            items: formatsToArrange,
            maxWidth,
            extraFormatHeight: FOOTER_HEIGHT_BIG,
            gap,
            zoom: zoomLevel,
            enableScaling: false,
            packerOptions: {
                smart: false,
                pot: false
            }
        });

        formatItems = formatItemsNested.map((format) => ({
            ...format.item,
            x: format.x,
            y: format.y
        }));

        const formatOrder = getTemplateData<State['formatOrder']>('state.formatOrder');
        const sortedFormats = FormatHelpers.sortFormats(formatOrder, true, formatItems);
        TemplateDesignerStore.save([['formats', sortedFormats]], { saveToHistory: false });
    };

    /**
     * Check if the format is in view.
     * @param formats - The formats to filter on.
     * @param scrollPosition - The scroll position (x, y).
     * @param zoomLevel - The zoom level of the canvas.
     * @returns Filtered formats that are in view.
     */
    static filterFormatsOutOfView = (formats: Format[], scrollPosition: { x: number; y: number }, zoomLevel: TDzoomLevel): Format[] => {
        const infiniteViewerRef = InfiniteViewerHelpers.getInfiniteViewer();

        if (!infiniteViewerRef) return formats;

        return formats.filter((format) => {
            const { containerWidth, containerHeight } = infiniteViewerRef.infiniteViewer;
            const infiniteViewerWidth = containerWidth / zoomLevel;
            const infiniteViewerHeight = containerHeight / zoomLevel;
            const scrollX = scrollPosition.x;
            const scrollY = scrollPosition.y;
            const infiniteViewerRangeX = [scrollX, scrollX + infiniteViewerWidth];
            const infiniteViewerRangeY = [scrollY, scrollY + infiniteViewerHeight];

            if (format.x === undefined) {
                format.x = 0;
            }

            if (format.y === undefined) {
                format.y = 0;
            }

            return !(
                format.x + format.width < infiniteViewerRangeX[0] ||
                format.x > infiniteViewerRangeX[1] ||
                format.y + format.height < infiniteViewerRangeY[0] ||
                format.y > infiniteViewerRangeY[1]
            );
        });
    };

    /**
     * Gets the previous keyframe that is relevant.
     * @param stamps The stamps to loop.
     * @param currentIndex  The current index.
     * @param containerSize The container size.
     * @param predefined  If false, no predefined animations are returned, if true only predefined animations are returned, if null all animations are returned.
     * @param attribute  The attribute to get the previous value from.
     * @param predefinedAnimationKey The predefined animation key to prevent predefined animations to reference other keyframes of the same animation
     * @returns The previous animation value
     */
    static getPreviousAnimationValue = (
        stamps: AnimationValue[],
        currentIndex: number,
        containerSize: { width: number; height: number },
        predefined: boolean | null,
        attribute: 'positionX' | 'positionY' | 'rotation' | 'opacity' | 'scale',
        predefinedAnimationKey?: string
    ): number | null => {
        let foundStamp: AnimationValue | null = null;
        // Iterate backwards from the currentIndex
        for (let i = currentIndex - 1; i >= 0; i--) {
            const stamp = stamps[i];

            const value = (() => {
                switch (attribute) {
                    case 'positionX':
                        return (stamp as PositionAnimation)?.value['x']?.value;
                    case 'positionY':
                        return (stamp as PositionAnimation)?.value['y']?.value;
                    case 'rotation':
                    case 'scale':
                        return (stamp as RotationAnimation)?.value?.value ?? (stamp as RotationAnimation)?.value;
                    default:
                        return stamp?.value;
                }
            })();

            // Check if the stamp has an x value
            if (
                (predefined !== null && !predefined && !stamp.predefinedAnimationKey && typeof value === 'number') ||
                (predefined !== null && predefined && stamp.predefinedAnimationKey && typeof value === 'number') ||
                (predefined === null && typeof value === 'number')
            ) {
                if (!(stamp.predefinedAnimationKey && predefinedAnimationKey && stamp.predefinedAnimationKey === predefinedAnimationKey)) {
                    foundStamp = stamp;
                    break;
                }
            }
        }

        if (!foundStamp) return null;

        if (attribute === 'positionX') {
            if (foundStamp.value['x']?.unit === '%') return (foundStamp.value['x'].value = (foundStamp.value['x'].value / 100) * containerSize.width);
            return foundStamp.value['x'].value;
        } else if (attribute === 'positionY') {
            if (foundStamp.value['y']?.unit === '%') return (foundStamp.value['y'].value = (foundStamp.value['y'].value / 100) * containerSize.width);
            return foundStamp.value['y'].value;
        } else if (attribute === 'rotation' || attribute === 'scale') {
            if (typeof foundStamp.value === 'number') {
                return foundStamp.value;
            }
            return (foundStamp as RotationAnimation).value.value;
        } else if (attribute === 'opacity') {
            return (foundStamp as OpacityAnimation).value;
        }

        return null;
    };

    /**
     * Some animations need to check previous keyframes to calculate the new value.
     * This is relevant if predefined animations and normal keyframes are mixed.
     * @param oldValue The old value of the animation
     * @param index The index of the animation
     * @param stamp The animation stamp
     * @param processedKeyframes The stamps to loop over to find the relevant previous keyframe
     * @param containerSize The size of the container
     * @param attribute The attribute of the animation
     * @returns The new value of the animation
     */
    static combineAnimationKeyframes = (
        oldValue: number,
        index: number,
        stamp: AnimationValue,
        processedKeyframes: AnimationValue[],
        containerSize: { width: number; height: number },
        attribute: 'positionX' | 'positionY' | 'rotation' | 'opacity' | 'scale'
    ): number => {
        if (!TemplateVersionHelpers.shouldCheckPreviousKeyframesAndCombine()) {
            return oldValue;
        }

        if (stamp.predefinedAnimationKey) {
            const prevValue =
                CanvasHelpers.getPreviousAnimationValue(
                    processedKeyframes,
                    index,
                    containerSize,
                    attribute === 'scale' ? false : null,
                    attribute,
                    stamp.predefinedAnimationKey
                ) ?? 0;
            return oldValue + prevValue;
        }
        if (attribute === 'opacity' || attribute === 'scale') return oldValue;
        const prevValue = CanvasHelpers.getPreviousAnimationValue(processedKeyframes, index, containerSize, true, attribute) ?? 0;
        return oldValue + prevValue;
    };

    /**
     * Generate keyframes that are passed to SceneJS.
     * @param layerProperties - All layer properties.
     * @param layers - Current frame layers.
     * @param frameType - Current frame.
     * @param formats - Formats to generate.
     * @param duration - Frame duration.
     * @param preview - For preview.
     * @returns A keyframes object for SceneJS.
     */
    static generateSceneKeyframes = (
        layerProperties: Template['layerProperties'],
        layers: Layer[],
        frameType: View['frameType'],
        formats: string[],
        duration: number,
        preview = false,
        enableAnimations = true
    ): any => {
        if (TemplateVersionHelpers.shouldNotRenderAnimationsIfDisabled() && !enableAnimations) {
            return {};
        }

        const layerPropertiesKeys = Object.keys(layerProperties);

        // Add 0.001 to the duration to prevent the last frame from being skipped.
        const newDuration = duration + 0.001;

        const compareKeyframeOverlap = (animation, comparingAnimation, animationKey, comparingAnimationKey) => {
            if (!animation || !comparingAnimation) return false;
            const animationTimes = animation.map((animation) => animation.stamp);
            const comparingAnimationTimes = comparingAnimation.map((animation) => animation.stamp);
            for (const stamp of animation) {
                if (stamp.stamp >= Math.min(...comparingAnimationTimes) && stamp.stamp <= Math.max(...comparingAnimationTimes)) {
                    return { [comparingAnimationKey]: [animationKey], [animationKey]: [comparingAnimationKey] };
                }
            }

            for (const comparingStamp of comparingAnimation) {
                if (comparingStamp.stamp >= Math.min(...animationTimes) && comparingStamp.stamp <= Math.max(...animationTimes)) {
                    return { [animationKey]: [comparingAnimationKey], [comparingAnimationKey]: [animationKey] };
                }
            }
            return false;
        };

        // get a 1 decimal timestamp based on the duration
        const formatStamp = (stamp) => {
            const newStamp = Math.round(stamp * newDuration * 100) / 100;
            return newStamp;
            // return (newStamp >= 0.01 ? newStamp - 0.01 : 0)
        };

        const getShadow = (shadow, shadowStyle) => {
            let value;
            if (shadow && shadow.shadowStyle !== 'none') {
                const { xOffset, yOffset, blur, spread, color } = shadow;
                const { r, g, b, a } = color.rgb;

                const style = shadowStyle ?? shadow.shadowStyle;

                value = `${style !== 'initial' ? style : ''} ${xOffset}px ${yOffset}px ${blur}px ${spread}px`;
                if (a < 1) {
                    value += ` rgba(${r},${g},${b},${a})`;
                } else {
                    value += ` ${color.hex}`;
                }
            } else {
                value = 'none';
            }

            return value;
        };

        const getColor = (rgb) => {
            const { r, g, b, a } = rgb;
            return `rgba(${r},${g},${b},${a})`;
        };

        const parseTransforms = (transform) => {
            const transforms = transform.match(/[a-z]+\([^)]+\)/gi) || [];
            const parsedTransforms = {};

            for (let i = 0; i < transforms.length; i++) {
                const [type, value] = transforms[i].match(/[a-z]+|[^()]+/gi);
                parsedTransforms[type] = value.trim();
            }

            return parsedTransforms;
        };

        const createObj = (layerKey, layerType) => {
            const obj = {};
            const allKeyframeOverlaps = {};
            // go through every format style
            layerPropertiesKeys.forEach((formatKey) => {
                if (formatKey === 'general' || !formats.includes(formatKey)) return;

                const selector = preview ? `.preview.${layerKey}.${formatKey}` : `.${layerKey}.${formatKey}`;

                const { layerProps } = LayerPropertiesHelpers.mergeLayerProperties(layerKey, frameType, [formatKey], layerProperties);
                const { transform } = StylingHelpers.getLayerStyle(layerProps.properties, layerProps.animations, layerType);

                const transformObject = transform ? parseTransforms(transform) : {};

                const visibility = LayerPropertiesHelpers.calculateActualVisibility(frameType, layerKey, formatKey, layerProperties);

                const visibilityObject: any = {
                    0: { visibility: 'hidden' },
                    [newDuration]: { visibility: 'visible' }
                };

                // handle the visibility of the layer
                // set start of visibility
                if (visibility[0] < 0) {
                    visibilityObject[0] = { visibility: 'visible' };
                } else {
                    visibilityObject[formatStamp(visibility[0])] = { visibility: 'visible' };
                }
                // set end of visibility
                if (visibility[1] < 1) {
                    visibilityObject[formatStamp(visibility[1])] = { visibility: 'hidden' };
                    visibilityObject[newDuration] = { visibility: 'hidden' };
                }

                // Check if the layer has animations.
                const hasAnimations =
                    layerProps.animations &&
                    !Array.isArray(layerProps.animations) &&
                    Object.keys(layerProps.animations).length > 0 &&
                    Object.values(layerProps.animations).some((animation) => animation && animation.length > 0);

                // If the layer has a visibility, add it to the scene object.
                // Check if the layer has animations, if not, we can return the object.
                if (Object.keys(visibilityObject).length > 2) {
                    obj[selector] = visibilityObject;
                }

                // Check if the layer has visibility changes.
                const hasVisibilityChanges = Object.keys(visibilityObject).length > 2 || visibility[1] < 1;

                // If the layer has no animations or visibility chnages, we don't need to add the layer to the scene object.
                if (!hasAnimations && !hasVisibilityChanges) return;

                obj[selector] = visibilityObject;

                // Splitting animations and predefined animations.
                let customAnimations = TimelineHelpers.filerAnimations(layerProps.animations, 'animations');
                const predefinedAnimationKeyframes = TimelineHelpers.filerAnimations(layerProps.animations, 'predefinedAnimations');

                // Converting predefined animations to normal animations.
                const [predefinedAnimations, allPredefinedAnimations] = this.getPredefinedAnimations(
                    predefinedAnimationKeyframes,
                    layerProps.properties as LayerProperties['properties'],
                    layerKey,
                    formatKey,
                    duration,
                    frameType
                );

                const allAnimations = cloneDeep(allPredefinedAnimations);
                allAnimations.custom = cloneDeep(customAnimations);

                //Merging the predefined animations with the custom animations
                if (predefinedAnimations && Object.keys(predefinedAnimations).length) {
                    // Ensure customAnimations is an object if it's undefined
                    customAnimations = customAnimations || {};

                    const animationKeys = [...new Set([...Object.keys(predefinedAnimations), ...Object.keys(customAnimations)])];

                    animationKeys.forEach((attrKey) => {
                        if (customAnimations && predefinedAnimations[attrKey]?.length) {
                            // Ensure customAnimations[attrKey] is an array before appending
                            customAnimations[attrKey] = [...(customAnimations[attrKey] || []), ...(predefinedAnimations[attrKey] || [])];
                        }
                    });
                }

                /**
                 * Keep track if a rotation or a scale keyframe is added.
                 * If there is not, only then apply the easing value to the keyframe.
                 * Otherwise, the default easing (linear) will overwrite the easing on other keyframes.
                 */
                let defaultRotationKeyframeAdded = false;
                let defaultScaleKeyframeAdded = false;

                if (TemplateVersionHelpers.shouldCheckPreviousKeyframesAndCombine()) {
                    // Add keyframes for transform properties if they do not exist to be able to use them in combination with user input
                    // The only for version 11 where also the animations are combined so we know for sure old templates dont break
                    if (customAnimations['rotation']?.length === 0) {
                        const keyframe: RotationAnimation = {
                            value: {
                                transformOrigin: {
                                    xOffset: 50,
                                    yOffset: 50
                                },
                                value: 0
                            },
                            id: '',
                            stamp: 0,
                            easing: {
                                type: 'linear',
                                value: 'linear'
                            }
                        };
                        customAnimations['rotation'] = [keyframe];
                        defaultRotationKeyframeAdded = true;
                    }
                    if (customAnimations['scale']?.length === 0) {
                        const keyframe: ScaleAnimation = {
                            value: {
                                transformOrigin: {
                                    xOffset: 50,
                                    yOffset: 50
                                },
                                value: 1
                            },
                            id: '',
                            stamp: 0,
                            easing: {
                                type: 'linear',
                                value: 'linear'
                            }
                        };
                        customAnimations['scale'] = [keyframe];
                        defaultScaleKeyframeAdded = true;
                    }
                }

                // set the frame values
                Object.entries(customAnimations).forEach(([attrKey, timestamps]) => {
                    const containerSize = LayerCalculationHelpers.getContainerSize(layerKey, formatKey, frameType);
                    const framesObject: any = { 0: {}, [newDuration]: {} };
                    const processedKeyframes: AnimationValue[] = [];
                    if ((timestamps as any).length > 0) {
                        timestamps = TimelineHelpers.sortKeyframes(timestamps);
                        (timestamps as any).forEach((stamp, index) => {
                            const stampKey = formatStamp(stamp.stamp);

                            // Check if the stamp is out of the duration. If so, skip the stamp.
                            if (stampKey > newDuration) return;

                            framesObject[stampKey] = {};
                            const animationTransform = {};

                            const easingValue = (() => {
                                if (stamp.easing.type === 'custom') {
                                    return `cubic-bezier(${stamp.easing.value})`;
                                }

                                return stamp.easing.value;
                            })();

                            if (attrKey === 'position') {
                                const { measurePoint } = layerProps.properties;
                                const v_dir = measurePoint === 'nw' || measurePoint === 'ne' ? 'top' : 'bottom';
                                const h_dir = measurePoint === 'nw' || measurePoint === 'sw' ? 'left' : 'right';

                                let defaultX = layerProps.properties.x?.value || 0;
                                defaultX = typeof defaultX === 'string' ? Number(defaultX) : defaultX;
                                if (layerProps.properties.x?.unit === '%') {
                                    defaultX = (defaultX / 100) * containerSize.width;
                                }

                                let defaultY = layerProps.properties.y?.value || 0;
                                defaultY = typeof defaultY === 'string' ? Number(defaultY) : defaultY;
                                if (layerProps.properties.y?.unit === '%') {
                                    defaultY = (defaultY / 100) * containerSize.height;
                                }

                                //We give a fallback of 0 for the missing x or y to keep the code a bit clearner. Oterwhise we would need to add exeptions to every calculation.
                                //At the end we check if the x and y were in the original stamp.value and if not we remove the values for that axis.
                                let x = stamp.value.x?.value ?? 0;
                                let y = stamp.value.y?.value ?? 0;

                                // when the position is set in percentages we have to recalculate the x and y values to pixels
                                // because the translate function with percentages is based on his own width and height instead of the container width and height

                                // convert the x and y values to pixels if it is percentage
                                if (stamp.value.x?.unit === '%') x = (x / 100) * containerSize.width;
                                if (stamp.value.y?.unit === '%') y = (y / 100) * containerSize.height;

                                x = x - defaultX;
                                y = y - defaultY;

                                // if vertical direction is set to right we have to invert the y value to make it possible to use the translate function
                                // it will go in the wrong direction because we should be retracting instead because we checking now from the bottom instead of top
                                if (v_dir === 'bottom') y = -y;
                                // if horizontal direction is set to right we have to invert the x value to make it possible to use the translate function
                                // it will go in the wrong direction because we should be retracting instead of adding on the x value
                                if (h_dir === 'right') x = -x;

                                const translateX = CanvasHelpers.combineAnimationKeyframes(x, index, stamp, processedKeyframes, containerSize, 'positionX');
                                const translateY = CanvasHelpers.combineAnimationKeyframes(y, index, stamp, processedKeyframes, containerSize, 'positionY');
                                processedKeyframes.push({ ...stamp, value: { x: { value: translateX, unit: 'px' }, y: { value: translateY, unit: 'px' } } });

                                animationTransform['translate'] = `${translateX}px, ${translateY}px`;

                                framesObject[stampKey]['easing'] = easingValue;
                            } else if (attrKey === 'size') {
                                // we are recalculating the width and height if it is set in percentages
                                // in some cases the width and height are set in percentages for one keyframe and in pixels for another keyframe
                                // in the case the animation glitches because it is uses the px of the second keyframe as pixels for the first keyframe or vice versa
                                // so we are recalculating the width and height to pixels if it is set in percentages
                                let width = stamp.value.width.value;
                                let height = stamp.value.height.value;

                                if (stamp.value.width.unit === '%') width = (width / 100) * containerSize.width;
                                if (stamp.value.height.unit === '%') height = (height / 100) * containerSize.height;

                                framesObject[stampKey].width = `${width}px`;
                                framesObject[stampKey].height = `${height}px`;
                                framesObject[stampKey].easing = easingValue;
                            } else if (attrKey === 'scale') {
                                if (!defaultScaleKeyframeAdded) {
                                    framesObject[stampKey].easing = easingValue;
                                }

                                // Extra check for old templates. Old situation: value === string. New situation: value === object.
                                if (typeof stamp.value === 'string' || typeof stamp.value === 'number') {
                                    const scale = CanvasHelpers.combineAnimationKeyframes(
                                        stamp.value,
                                        index,
                                        stamp,
                                        processedKeyframes,
                                        containerSize,
                                        'scale'
                                    );
                                    processedKeyframes.push({
                                        ...stamp,
                                        value: { value: scale }
                                    });

                                    animationTransform['scale'] = `${scale}`;
                                } else {
                                    let scale = stamp.value.value;

                                    if (TemplateVersionHelpers.shouldCheckPreviousKeyframesAndCombine()) {
                                        scale = CanvasHelpers.combineAnimationKeyframes(scale, index, stamp, processedKeyframes, containerSize, 'scale');
                                        processedKeyframes.push({
                                            ...stamp,
                                            value: { value: scale }
                                        });
                                    }

                                    animationTransform['scale'] = `${scale}`;
                                    framesObject[stampKey]['transform-origin'] =
                                        `${stamp.value.transformOrigin.xOffset}% ${stamp.value.transformOrigin.yOffset}%`;
                                }
                            } else if (attrKey === 'rotation') {
                                if (!defaultRotationKeyframeAdded) {
                                    framesObject[stampKey].easing = easingValue;
                                }

                                // Extra check for old templates. Old situation: value === string. New situation: value === object.
                                if (typeof stamp.value === 'string' || typeof stamp.value === 'number') {
                                    const rotation = CanvasHelpers.combineAnimationKeyframes(
                                        stamp.value,
                                        index,
                                        stamp,
                                        processedKeyframes,
                                        containerSize,
                                        'rotation'
                                    );
                                    processedKeyframes.push({ ...stamp, value: { value: rotation } });

                                    animationTransform['rotate'] = `${rotation}deg`;
                                } else {
                                    const rotation = CanvasHelpers.combineAnimationKeyframes(
                                        stamp.value.value,
                                        index,
                                        stamp,
                                        processedKeyframes,
                                        containerSize,
                                        'rotation'
                                    );
                                    processedKeyframes.push({ ...stamp, value: { value: rotation } });

                                    animationTransform['rotate'] = `${rotation}deg`;
                                    framesObject[stampKey]['transform-origin'] =
                                        `${stamp.value.transformOrigin.xOffset}% ${stamp.value.transformOrigin.yOffset}%`;
                                }
                            } else if (attrKey === 'opacity') {
                                let opacity = stamp.value;

                                if (TemplateVersionHelpers.shouldCheckPreviousKeyframesAndCombine()) {
                                    const previousKeyframe = processedKeyframes[processedKeyframes.length - 1];
                                    if (stamp.predefinedAnimationKey && previousKeyframe && !previousKeyframe.predefinedAnimationKey) {
                                        opacity = previousKeyframe.value;
                                    }
                                }

                                framesObject[stampKey].opacity = opacity;
                                framesObject[stampKey].easing = easingValue;
                                processedKeyframes.push({ ...stamp, value: opacity });
                            } else if (attrKey === 'color' || attrKey === 'backgroundColor') {
                                if (layerType === 'text') {
                                    if (attrKey === 'color') {
                                        if (stamp.value.type === 'transparent') {
                                            framesObject[stampKey].color = 'rgba(0,0,0,0)';
                                        } else {
                                            framesObject[stampKey].color = getColor(stamp.value.rgb);
                                        }
                                    } else {
                                        framesObject[stampKey]['background-color'] = getColor(stamp.value.rgb);
                                    }
                                } else {
                                    if (stamp.value.type === 'transparent') {
                                        framesObject[stampKey]['background-color'] = 'rgba(0,0,0,0)';
                                    } else {
                                        framesObject[stampKey]['background-color'] = getColor(stamp.value.rgb);
                                    }
                                }

                                framesObject[stampKey].easing = easingValue;
                            } else if (attrKey === 'shadow') {
                                const shadowStyle = timestamps[0].value.shadowStyle;
                                framesObject[stampKey]['box-shadow'] = getShadow(stamp.value, shadowStyle);
                                framesObject[stampKey].easing = easingValue;
                            }

                            // Merge properties transform with animation transform.
                            if (Object.keys(animationTransform).length) {
                                let transformString = '';

                                const getTransformItem = (key) => {
                                    if (animationTransform[key]) {
                                        return `${key}(${animationTransform[key]}) `;
                                    }

                                    if (transformObject[key]) {
                                        return `${key}(${transformObject[key]}) `;
                                    }

                                    return '';
                                };

                                // we are merging the transform properties of the layer with the animation transform properties
                                // and we are putting them in the correct order so the layer doesnt glitch when the transform properties are set
                                transformString += getTransformItem('translate');
                                transformString += getTransformItem('translateX');
                                transformString += getTransformItem('translateY');
                                transformString += getTransformItem('rotate');
                                transformString += getTransformItem('skew');
                                transformString += getTransformItem('scale');

                                framesObject[stampKey].transform = transformString;
                            }
                        });

                        Object.keys(framesObject).forEach((frameKey) => {
                            Object.keys(framesObject[frameKey]).forEach((styleKey) => {
                                const transformExists =
                                    obj[`.${layerKey}.${formatKey}`] &&
                                    obj[`.${layerKey}.${formatKey}`][frameKey] &&
                                    obj[`.${layerKey}.${formatKey}`][frameKey]['transform'];

                                if (styleKey === 'transform' && transformExists) {
                                    framesObject[frameKey][styleKey] = obj[`.${layerKey}.${formatKey}`][frameKey][styleKey] +=
                                        ' ' + framesObject[frameKey][styleKey];
                                }
                            });
                        });

                        obj[selector] = merge(obj[selector], framesObject);
                    }
                });

                //Check if predefined animations overlap with custom animations or other predefined animations
                if (Object.keys(allPredefinedAnimations).length) {
                    //Compare all animations with each other
                    for (const animationKey of Object.keys(allAnimations)) {
                        for (const comparingAnimationKey of Object.keys(allAnimations)) {
                            //Do not compare the animation to itself
                            if (comparingAnimationKey === animationKey) break;

                            //Loop through all attributes of the animation
                            for (const attrKey of Object.keys(allAnimations[animationKey])) {
                                //If one is empty or both have one it can not overlap or if the overlap is already found
                                //Cannot overlap will be in most cases
                                const customKey = animationKey === 'custom' ? attrKey : animationKey;
                                const customComparingKey = comparingAnimationKey === 'custom' ? attrKey : comparingAnimationKey;
                                const cannotOverlap =
                                    allAnimations[animationKey].length === 0 ||
                                    allAnimations[comparingAnimationKey].length === 0 ||
                                    (allAnimations[animationKey].length < 2 && allAnimations[comparingAnimationKey].length < 2);
                                const overlapAlreadyFound =
                                    allKeyframeOverlaps[customKey]?.includes(customComparingKey) ||
                                    allKeyframeOverlaps[customComparingKey]?.includes(customKey);

                                if (cannotOverlap || overlapAlreadyFound) break;

                                //If animationKey == custom it means it is a custom animation prop otherwise it is a predefined animation
                                const newKeyframeOverlaps = compareKeyframeOverlap(
                                    allAnimations[animationKey][attrKey],
                                    allAnimations[comparingAnimationKey][attrKey],
                                    customKey,
                                    customComparingKey
                                );

                                //If overlaps are found add them to the layerKeyframeOverlaps object
                                if (newKeyframeOverlaps) {
                                    [...Object.keys(newKeyframeOverlaps), ...Object.keys(allKeyframeOverlaps)].forEach((key) => {
                                        allKeyframeOverlaps[key] = uniq([
                                            ...(cloneDeep(allKeyframeOverlaps[key]) || []),
                                            ...(cloneDeep(newKeyframeOverlaps[key]) || [])
                                        ]);
                                    });
                                }
                            }
                        }
                    }
                }
            });

            const oldKeyFrameOverlaps = ComponentStore.get('TemplateDesigner').state.keyframeOverlaps?.[layerKey] || {};
            if (!isEqual(oldKeyFrameOverlaps, allKeyframeOverlaps) && layerKey) {
                TemplateDesignerStore.save(['state.keyframeOverlaps.' + layerKey, allKeyframeOverlaps], { saveToHistory: false });
            }

            return obj;
        };

        // create object    example ==> { '.class': { 0: { display: 'block' } } }
        const addCSS = (accumulator, current) => {
            const updatedAccumulator = { ...accumulator, ...createObj(current.key, current.type) };

            if (current.children && current.children.length) {
                return { ...updatedAccumulator, ...current.children.reduce(addCSS, {}) };
            }

            return updatedAccumulator;
        };

        const keyframes = layers.reduce(addCSS, {});

        return keyframes;
    };

    /**
     * Convert the predefined animations to the correct animations.
     * @param predefinedAnimations - The predefined animations.
     * @param properties - The properties of the layer.
     * @param layerKey - The key of the layer.
     * @param formatKey - The key of the format.
     * @returns Normal animations but with the keyframes of the predefined animations all predefined animations end up in one animations object.
     */
    static getPredefinedAnimations = (
        predefinedAnimations: PredefinedAnimations,
        properties: LayerProperties['properties'],
        layerKey: string,
        formatKey: string,
        duration: number,
        frameType: FrameType['key']
    ): [
        convertedPredefinedAnimations: LayerProperties['animations'],
        allPredefinedAnimations: { [key: PredefinedAnimationsKey]: LayerProperties['animations'] }
    ] => {
        const convertedPredefinedAnimations: LayerProperties['animations'] = {
            color: [],
            backgroundColor: [],
            opacity: [],
            position: [],
            rotation: [],
            scale: [],
            shadow: [],
            size: []
        };
        const allPredefinedAnimations: { [key: PredefinedAnimationsKey]: LayerProperties['animations'] } = {};

        if (!predefinedAnimations || !Object.keys(predefinedAnimations).length) return [convertedPredefinedAnimations, allPredefinedAnimations];

        Object.keys(predefinedAnimations).forEach((predefinedAnimationKey) => {
            if (predefinedAnimations[predefinedAnimationKey]?.length < 2) return;
            const currentPredefinedAnimationsSetup = getPredefinedAnimationsSetup();

            //Getting data about what animation it is and getting the default data / setup
            const animationType: PredefinedAnimation['type'] = predefinedAnimations[predefinedAnimationKey]?.[0].type;
            const animationCategory =
                Object.keys(currentPredefinedAnimationsSetup).find((animationCategory) => {
                    return Object.keys(currentPredefinedAnimationsSetup[animationCategory].animations).includes(animationType);
                }) || '';
            const predefinedAnimationSetup: PredefinedAnimationSetup = cloneDeep(
                currentPredefinedAnimationsSetup[animationCategory]?.animations[animationType]
            );

            if (!predefinedAnimationSetup) return;

            //Getting data for the length of the animation
            const keyframes: PredefinedAnimationValue = predefinedAnimations[predefinedAnimationKey];
            const lengthInPercentage = keyframes[1].stamp - keyframes[0].stamp;
            const newAnimationDuration = duration * lengthInPercentage;

            //Beginning of converting the animations
            let convertedPredefinedAnimation: NonNullable<LayerProperties['animations']> = cloneDeep(predefinedAnimationSetup.stamps) ?? {};

            const loop = predefinedAnimations[predefinedAnimationKey][0]?.value?.loop?.value || false;
            if (loop) {
                convertedPredefinedAnimation = this.loopAnimation(convertedPredefinedAnimation, loop + 1);
            }

            Object.keys(convertedPredefinedAnimation).forEach((attrKey) => {
                convertedPredefinedAnimation[attrKey].forEach((stamp, index) => {
                    //Change the animation values based on the input of the user
                    convertedPredefinedAnimation = this.convertPredefinedAttributes(
                        convertedPredefinedAnimation,
                        predefinedAnimations[predefinedAnimationKey][0],
                        attrKey as AnimationOptions,
                        index,
                        properties,
                        layerKey,
                        formatKey,
                        frameType
                    );
                    //change the timing of the stamp
                    convertedPredefinedAnimation[attrKey][index].stamp = TimelineHelpers.translateTimestamp(
                        stamp.stamp,
                        keyframes[0].stamp,
                        duration,
                        newAnimationDuration
                    );
                    convertedPredefinedAnimation[attrKey][index].predefinedAnimationKey = predefinedAnimationKey;
                });
                allPredefinedAnimations[predefinedAnimationKey] = convertedPredefinedAnimation;
                //Add the animation to the animations object
                convertedPredefinedAnimations[attrKey] = [...(convertedPredefinedAnimations[attrKey] || []), ...convertedPredefinedAnimation[attrKey]];
            });
        });

        return [convertedPredefinedAnimations, allPredefinedAnimations];
    };

    /**
     * Transform the attributes of the predefined animation with the input from the user.
     * @param convertedPredefinedAnimation - The animation that is being converted.
     * @param predefinedKeyframe - The keyframe of the predefined animation.
     * @param attrKey - The attribute that is being converted.
     * @param index - The index of the keyframe.
     * @param properties - The properties of the layer.
     * @param layerKey - The key of the layer.
     * @param formatKey - The key of the format.
     * @returns The converted animation.
     */
    private static convertPredefinedAttributes = (
        convertedPredefinedAnimation: LayerProperties['animations'],
        predefinedKeyframe: PredefinedAnimationValue,
        attrKey: AnimationOptions,
        index: number,
        properties: LayerProperties['properties'],
        layerKey: string,
        formatKey: string,
        frameType: FrameType['key']
    ): NonNullable<LayerProperties['animations']> => {
        if (convertedPredefinedAnimation === undefined) convertedPredefinedAnimation = {};
        // Some other transformation are needed before the animation can be used
        const newAnimation: NonNullable<LayerProperties['animations']> = cloneDeep(convertedPredefinedAnimation);

        // Checking on attribute color because it can be undefined
        if (attrKey === 'color') return convertedPredefinedAnimation;

        if (newAnimation?.[attrKey]?.[index]?.easing) {
            (newAnimation[attrKey] as any)[index].easing = predefinedKeyframe.easing;
        }

        if (predefinedKeyframe.value) {
            //The input is scaled by the predefined animation keyframe. All predefined keyframes are in percentages.
            //The predefinedKeyframe values are modifiers rather than absolute values.
            switch (attrKey) {
                case 'position': {
                    newAnimation.position = this.convertPosition(predefinedKeyframe, newAnimation, properties, index, layerKey, formatKey, frameType);
                    break;
                }
                case 'rotation':
                    newAnimation.rotation = this.convertRotation(predefinedKeyframe, newAnimation, index);
                    break;
                case 'scale':
                    newAnimation.scale = this.convertScale(predefinedKeyframe, newAnimation, index);
                    break;
            }
        }

        return newAnimation;
    };

    /**
     * Convert the position animation based on layer properties and the input of the user
     * @param predefinedKeyframe This is the keyframe / the user input on the right side with the direction and for example distance or size and easing.
     * @param animation This is the animation out of the predefined animation library.
     * @param properties The properties of the layer.
     * @param index Which keyframe out of the animation we are currently converting.
     * @param layerKey Key of the layer
     * @param formatKey key of the format
     * @returns the converted position animation
     */
    static convertPosition = (
        predefinedKeyframe: PredefinedAnimationValue,
        animation: LayerProperties['animations'],
        properties: LayerProperties['properties'],
        index: number,
        layerKey: string,
        formatKey: string,
        frameType: FrameType['key']
    ): PositionAnimation[] => {
        if (animation === undefined) animation = {};
        const positionAnimation: PositionAnimation[] = cloneDeep(animation.position);
        if (!positionAnimation.length || !predefinedKeyframe.value?.['position']) return positionAnimation;

        //We fill in unused values to keep the code a bit clearner. Oterwhise we would need to add exeptions to every calculation.
        //At the end we check if the x and y were in the original animation.position and if not we remove the values for that axis.
        if (!positionAnimation[index].value.x) positionAnimation[index].value.x = { value: 0, unit: 'px' };
        if (!positionAnimation[index].value.y) positionAnimation[index].value.y = { value: 0, unit: 'px' };

        //If degrees are used the standard direction is up
        const direction: PredefinedPositionAnimation['value']['position']['direction'] =
            predefinedKeyframe.value['position'].direction === 'degrees' ? 'down' : predefinedKeyframe.value['position'].direction;
        const layer = document.querySelector(`.${layerKey}`);

        const layerSize = {
            width: {
                value:
                    (direction === 'right' || direction === 'left'
                        ? Number(properties.width.value || layer?.clientWidth)
                        : Number(properties.height.value || layer?.clientHeight)) || 0,
                unit: properties.width.unit
            },
            height: {
                value:
                    (direction === 'up' || direction === 'down'
                        ? Number(properties.height.value || layer?.clientHeight)
                        : Number(properties.width.value || layer?.clientWidth)) || 0,
                unit: properties.height.unit
            }
        };

        const userInputX = predefinedKeyframe.value['position'].x || { value: 0, unit: 'px' };
        const userInputY = predefinedKeyframe.value['position'].y || { value: 0, unit: 'px' };

        let xAnimation = 0;
        if (userInputX.unit === '%') {
            if (layerSize.width.unit === '%') {
                //In this case the output unit is %
                xAnimation = layerSize.width.value * ((userInputX.value / 100) * (positionAnimation[index].value.x.value / 100)) || 0;
            } else {
                //In this case the output unit is px
                xAnimation = layerSize.width.value * ((userInputX.value * (positionAnimation[index].value.x.value / 100)) / 100) || 0;
            }
        } else {
            //In this case the output unit is px
            xAnimation = userInputX.value * (positionAnimation[index].value.x.value / 100) || 0;
        }

        let yAnimation = 0;
        if (userInputY.unit === '%') {
            if (layerSize.height.unit === '%') {
                //In this case the output unit is %
                yAnimation = layerSize.height.value * ((userInputY.value / 100) * (positionAnimation[index].value.y.value / 100)) || 0;
            } else {
                //In this case the output unit is px
                yAnimation = layerSize.height.value * ((userInputY.value * (positionAnimation[index].value.y.value / 100)) / 100) || 0;
            }
        } else {
            //In this case the output unit is px
            yAnimation = userInputY.value * (positionAnimation[index].value.y.value / 100) || 0;
        }

        //Setting output unit
        positionAnimation[index].value.x.unit = layerSize.width.unit === 'px' && userInputX.unit === '%' ? 'px' : userInputX.unit;
        positionAnimation[index].value.y.unit = layerSize.height.unit === 'px' && userInputY.unit === '%' ? 'px' : userInputY.unit;

        //Correction based on direction
        //Default is down (right)
        if (direction === 'up') {
            //up (left)
            xAnimation = -xAnimation;
            yAnimation = -yAnimation;
        }

        if (direction === 'left') {
            //left (down)
            const oldXAnimation = xAnimation;
            xAnimation = -yAnimation;
            yAnimation = oldXAnimation;
            const oldXUnit = positionAnimation[index].value.x.unit;
            positionAnimation[index].value.x.unit = positionAnimation[index].value.y.unit;
            positionAnimation[index].value.y.unit = oldXUnit;
        }

        if (direction === 'right') {
            //right (up)
            const oldXAnimation = xAnimation;
            xAnimation = yAnimation;
            yAnimation = -oldXAnimation;
            const oldXUnit = positionAnimation[index].value.x.unit;
            positionAnimation[index].value.x.unit = positionAnimation[index].value.y.unit;
            positionAnimation[index].value.y.unit = oldXUnit;
        }

        // we have to calculate the real property x and y because the user can use % as unit
        // and we always use pixels to calculate positions
        const containerSize = LayerCalculationHelpers.getContainerSize(layerKey, formatKey, frameType);
        const propertyX: number = (() => {
            if (properties['x'].unit === positionAnimation[index].value.x.unit) {
                return Number(properties['x']?.value);
            }
            if (properties['x'].unit === '%') {
                return (Number(properties['x'].value) / 100) * containerSize.width;
            }
            if (properties['x'].unit === 'px') {
                return (Number(properties['x'].value) / containerSize.width) * 100;
            }
            return Number(properties['x']?.value);
        })();
        const propertyY: number = (() => {
            if (properties['y'].unit === positionAnimation[index].value.y.unit) {
                return Number(properties['y']?.value);
            }
            if (properties['y'].unit === '%') {
                return (Number(properties['y'].value) / 100) * containerSize.height;
            }
            if (properties['y'].unit === 'px') {
                return (Number(properties['y'].value) / containerSize.height) * 100;
            }
            return Number(properties['y'].value);
        })();

        if (positionAnimation?.[index]?.value.x.value !== undefined && propertyX !== undefined) {
            positionAnimation[index].value.x.value = propertyX + xAnimation;
        }

        if (positionAnimation?.[index]?.value.y.value !== undefined && propertyY !== undefined) {
            positionAnimation[index].value.y.value = propertyY + yAnimation;
        }

        //For position the already existing position is being added to get the correct position
        if (properties['measurePoint'] === 'ne' || properties['measurePoint'] === 'se') {
            positionAnimation[index].value.x.value = propertyX - xAnimation;
        }
        if (properties['measurePoint'] === 'se' || properties['measurePoint'] === 'sw') {
            positionAnimation[index].value.y.value = propertyY - yAnimation;
        }

        if (predefinedKeyframe.value?.['position'].direction === 'degrees') {
            //Take the result of the animation and rotate it on the circle
            const { rotatedX, rotatedY } = this.rotatePointOnCircle(
                propertyX,
                propertyY,
                propertyY - positionAnimation[index].value.y.value,
                predefinedKeyframe.value?.['position'].directionDegrees - 90
            );
            positionAnimation[index].value.x.value = rotatedX;
            positionAnimation[index].value.y.value = rotatedY;
        }

        return positionAnimation;
    };

    /**
     * Scale a value from one range to another.
     * @param centerX - The x coordinate of the center of the circle.
     * @param centerY - The y coordinate of the center of the circle.
     * @param radius - The radius of the circle.
     * @param angleDegrees - The angle in degrees.
     * @returns The new coordinates after rotation.
     */
    static rotatePointOnCircle = (centerX: number, centerY: number, radius: number, angleDegrees: number): { rotatedX: number; rotatedY: number } => {
        // Convert the angle from degrees to radians
        const angleRadians = (angleDegrees * Math.PI) / 180;

        // Calculate the new coordinates after rotation
        const newX = centerX + radius * Math.cos(angleRadians);
        const newY = centerY + radius * Math.sin(angleRadians);

        // Return the new coordinates as an object
        return { rotatedX: newX, rotatedY: newY };
    };

    private static convertRotation = (
        predefinedKeyframe: PredefinedAnimationValue,
        animation: LayerProperties['animations'],
        index: number
    ): RotationAnimation[] => {
        if (animation === undefined) animation = {};
        const rotationAnimation = cloneDeep(animation.rotation);
        if (!rotationAnimation.length || !predefinedKeyframe.value?.['rotation']) return rotationAnimation;

        const degrees = rotationAnimation[index].value.value ?? 1;

        const degreesInput = predefinedKeyframe.value['rotation'].value / 100 ?? 1;

        if (predefinedKeyframe.value['rotation'].direction === 'left') {
            rotationAnimation[index].value.value = -(degrees * degreesInput);
        } else {
            rotationAnimation[index].value.value = degrees * degreesInput;
        }

        return rotationAnimation;
    };

    /**
     * Convert predefined scale animation to the correct scale animation.
     * @param predefinedKeyframe - The keyframe of the predefined animation.
     * @param animations - The animations of the layer.
     * @param index - The index of the keyframe.
     * @returns The converted scale animation.
     */
    private static convertScale = (predefinedKeyframe: PredefinedAnimationValue, animation: LayerProperties['animations'], index: number): ScaleAnimation[] => {
        if (animation === undefined) animation = {};

        const scaleAnimation: PredefinedSetupScaleAnimation[] = cloneDeep(animation.scale);
        if (!scaleAnimation.length || !predefinedKeyframe.value?.['scale']) {
            //Return the default value
            if (scaleAnimation[index].value.value === 'initial') {
                scaleAnimation[index].value.value = 1;
            }
            return scaleAnimation as ScaleAnimation[];
        }

        const scale = scaleAnimation[index].value.value ?? 1;
        const scaleInput = predefinedKeyframe.value['scale'].value ?? 1;

        if (scale === 'initial') {
            scaleAnimation[index].value.value = 1;
        } else {
            scaleAnimation[index].value.value = scale * scaleInput;
        }

        return scaleAnimation as ScaleAnimation[];
    };

    /**
     * Scale a value based on a from and to range.
     * @param value - Value to be scaled.
     * @param from - Array of two values that represent the range of the value.
     * @param to - Array of two values that represent the range of the value.
     * @returns Scaled value.
     */
    private static scaleValue = (value: number, from: [number, number], to: [number, number]): number => {
        const scale = (to[1] - to[0]) / (from[1] - from[0]);
        const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
        return capped * scale + to[0];
    };

    /**
     *  Loop the animation based on the input of the user
     * @param animation the animation that is being looped
     * @param loopAmount the amount of times the animation is being looped
     * @returns the new looped animation
     */
    private static loopAnimation = (animation: LayerProperties['animations'], loopAmount: number): NonNullable<LayerProperties['animations']> => {
        const newAnimation: LayerProperties['animations'] = {};
        if (animation === undefined) animation = {};

        for (let i = 0; i < loopAmount; i++) {
            Object.keys(animation).forEach((attrKey) => {
                if (animation?.[attrKey]) {
                    const scaledAnimation = cloneDeep(animation[attrKey]);
                    scaledAnimation.forEach((keyframe) => {
                        const loopTime = 1 / loopAmount;
                        keyframe.stamp = this.scaleValue(keyframe.stamp, [0, 1], [loopTime * i, loopTime * (i + 1)]);
                    });
                    if (!newAnimation[attrKey]) {
                        newAnimation[attrKey] = [];
                    }
                    newAnimation[attrKey] = [...newAnimation[attrKey], ...scaledAnimation];
                }
            });
        }

        return newAnimation;
    };

    /**
     * Load all the media layers in the canvas.
     * @returns A promise that resolves when all the media layers are loaded.
     */
    static loadMedia = (): Promise<void>[] => {
        return this.getTemplateMedia()
            .filter((media) => media.src !== '')
            .map((media) => {
                return new Promise<void>((resolve) => {
                    if (media.readyState >= 2) {
                        // If the video is already loaded (readyState >= 2), resolve immediately.
                        resolve();
                    } else {
                        // Otherwise, wait for the 'loadeddata' event to be triggered.
                        media.addEventListener('loadeddata', function handleLoadedData() {
                            media.removeEventListener('loadeddata', handleLoadedData);
                            resolve();
                        });
                    }
                });
            });
    };

    /**
     * Get all the media layers from the canvas.
     * @returns All the media layers in the canvas.
     */
    static getTemplateMedia = (): (HTMLVideoElement | HTMLAudioElement)[] => {
        return [
            ...document.querySelectorAll<HTMLVideoElement>('video[data-format-key]'),
            ...document.querySelectorAll<HTMLAudioElement>('audio[data-format-key]')
        ];
    };

    /**
     * Get all the Lottie layers from the canvas.
     * @returns All Lottie layers in the canvas.
     */
    static getLottieMedia = (): HTMLDivElement[] => {
        return [...document.querySelectorAll<HTMLDivElement>('.layers-container__layer[class*="lottie_"]')];
    };

    /**
     * Manage the media layers.
     * @param play - If the media layers should play or pause.
     * @param currentTime - The current time of the timeline.
     * @param duration - The duration of the timeline.
     */
    static manageMediaLayers = (play: boolean, currentTime: number, duration: number): void => {
        const formatsWithAudio = getTemplateData<State['formatsWithAudio']>('state.formatsWithAudio');

        // Clear all the timeouts so they will not run later.
        if (this.mediaTimeouts && Object.keys(this.mediaTimeouts).length) {
            Object.keys(this.mediaTimeouts).forEach((timeout) => clearTimeout(this.mediaTimeouts[timeout]));
        }

        /**
         * Hide the media layer when the video is over.
         * @param media - The media layer.
         * @param hide - If the media layer should be hidden.
         */
        function hideMediaWhenFinished(media: HTMLVideoElement | HTMLAudioElement, hide: boolean): void {
            if (hide) {
                media.style.display = 'none';
            } else {
                media.style.display = 'flex';
            }
        }

        CanvasHelpers.getTemplateMedia().forEach((media) => {
            const format = media.dataset.formatKey;
            const layer = media.dataset.layerKey;

            /**
             * Check if the media layer should be displayed based on the layer properties.
             * If the display is set to false, don't manage the media layer.
             */
            if (format && layer) {
                const frameType = getTemplateData<View['frameType']>('view.frameType');
                const display = TemplateDesignerStore.getModelWithFallback([
                    `layerProperties.${format}.${frameType}.${layer}.properties.display`,
                    `layerProperties.general.${frameType}.${layer}.properties.display`
                ]);

                if (display === false) {
                    return;
                }
            }

            // Get the media's properties.
            let volume = media.dataset.volume === undefined ? 1 : Number(media.dataset.volume);
            const loop = media.loop;
            const hideWhenFinished = media.dataset.hideWhenFinished === 'true' && !loop; // Hide the media layer when the video is over and loop is disabled.
            const visibility = media.dataset?.visibility?.split(',') ?? [0, 1];

            if (format && !formatsWithAudio.includes(format)) volume = 0;

            // Get the start time based on the visibility set in the data attributes.
            const startTime = (() => {
                if (Number(visibility[0]) === 0) return 0;
                return Number(visibility[0]) * duration;
            })();

            // Get the end time based on the visibility set in the data attributes.
            const endTime = (() => {
                const visibilityEndTime = (() => {
                    if (Number(visibility[1]) === 1) return duration;
                    return Number(visibility[1]) * duration;
                })();

                // If the media layer should end later, it should add the start time to the duration of the media.
                if (TemplateVersionHelpers.shouldMediaLayersEndLater()) {
                    return Math.min(visibilityEndTime, media.duration + startTime);
                }

                return Math.min(visibilityEndTime, media.duration);
            })();

            // If there is no start or end time, we can't manage the media layer.
            if (isNaN(startTime) || isNaN(endTime)) return;

            // No need to play the audio layer if the volume is 0.
            if (media.localName === 'audio' && volume === 0) {
                return media.pause();
            }

            if (currentTime >= startTime && currentTime <= endTime) {
                const newCurrentTime = currentTime - startTime;
                media.currentTime = newCurrentTime;

                if (play) {
                    media.play();
                } else {
                    media.pause();
                }

                hideMediaWhenFinished(media, false);
            } else if (currentTime < startTime) {
                if (play) {
                    const delay = (startTime - currentTime) * 1000;

                    // Create a timeout so the media will play after the start time has exceeded.
                    this.mediaTimeouts[media.id + '_start'] = setTimeout(() => {
                        media.currentTime = 0;
                        media.play();
                        hideMediaWhenFinished(media, false);
                    }, delay);
                } else {
                    media.currentTime = 0;
                    media.pause();
                }
            } else if (loop && currentTime > endTime) {
                /**
                 * Calculate new current time if the media layer loop is enabled.
                 * Example: Start time = 0. Media duration = 2. Current time = 3. Media duration should be 1. (Second iteration of the loop)
                 */
                const newCurrentTime = currentTime % (endTime - startTime);
                media.currentTime = newCurrentTime;

                if (play) {
                    media.play();
                    hideMediaWhenFinished(media, hideWhenFinished);
                } else {
                    media.pause();
                }
            } else if (!loop && currentTime > endTime) {
                media.pause();
                hideMediaWhenFinished(media, hideWhenFinished);
            }

            /**
             * Stop the media layer after the end time has exceeded (performance)
             */
            if (play && !loop) {
                const delay = (endTime - currentTime) * 1000;

                this.mediaTimeouts[media.id + '_end'] = setTimeout(() => {
                    // Set the current time to 0 if it has the version that doesn't end later.
                    if (!TemplateVersionHelpers.shouldMediaLayersEndLater()) {
                        media.currentTime = 0;
                    }

                    media.pause();
                    hideMediaWhenFinished(media, hideWhenFinished);
                }, delay);
            }

            media.volume = volume;
        });
    };

    /**
     * Manage the Lottie layers.
     * @param play - If the Lottie animation should play or pause.
     * @param currentTime - The current time of the timeline.
     * @param duration - The duration of the timeline.
     */
    static manageLottieLayers = (play: boolean, currentTime: number, duration: number): void => {
        // Clear all the timeouts so they will not run later.
        if (this.lottieTimeouts && Object.keys(this.lottieTimeouts).length) {
            Object.keys(this.lottieTimeouts).forEach((timeout) => clearTimeout(this.lottieTimeouts[timeout]));
        }

        CanvasHelpers.getLottieMedia().forEach((media) => {
            if (media.style.display === 'none') return;

            // Get the media's properties.
            const visibility = media.dataset?.visibility?.split(',') ?? [0, 1];
            const currentLottieName = media.dataset['lottieName'];
            // ? getRegisteredAnimations doesn't exists on Lottie type, but it does work.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const lottieAnimations = lottie.getRegisteredAnimations();
            const currentLottie = lottieAnimations.find((lottieAnimation) => lottieAnimation.name === currentLottieName);

            if (!currentLottie) return;

            // Calculate the Lottie animation by checking the frame rate and total frames.
            const frameRate = currentLottie.frameRate;
            const totalFrames = currentLottie.totalFrames;
            const lottieDuration = totalFrames / frameRate;

            // Get the start time based on the visibility set in the data attributes.
            const startTime = (() => {
                if (Number(visibility[0]) === 0) return 0;
                return Number(visibility[0]) * duration;
            })();

            // Get the end time based on the visibility set in the data attributes.
            const endTime = (() => {
                const visibilityEndTime = (() => {
                    if (Number(visibility[1]) === 1) return duration;
                    return Number(visibility[1]) * duration;
                })();

                return Math.min(visibilityEndTime, lottieDuration) + startTime;
            })();

            // If there is no start or end time, we can't manage the Lottie layer.
            if (isNaN(startTime) || isNaN(endTime)) return;

            if (currentTime >= startTime && currentTime <= endTime) {
                const newCurrentTime = (currentTime - startTime) * 1000;
                // ? goToAndStop doesn't exists on Lottie type, but it does work.
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                lottie.goToAndStop(newCurrentTime, false, currentLottieName);

                if (play) {
                    currentLottie.play();
                } else {
                    currentLottie.pause();
                }
            } else if (currentTime < startTime) {
                /**
                 * If the current time is before the start time.
                 * If the media should play, delay it by the start time.
                 * Otherwise set current time to 0 and pause the media.
                 */
                if (play) {
                    const delay = (startTime - currentTime) * 1000;

                    // Create a timeout so the Lottie animation will play after the start time has exceeded.
                    this.lottieTimeouts[media.id + '_start'] = setTimeout(() => {
                        // ? goToAndStop doesn't exists on Lottie type, but it does work.
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        lottie.goToAndStop(0, false, currentLottieName);
                        currentLottie.play();
                    }, delay);
                } else {
                    // ? goToAndStop doesn't exists on Lottie type, but it does work.
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    lottie.goToAndStop(0, false, currentLottieName);
                    currentLottie.play();
                }
            } else if (currentTime > endTime) {
                // ? goToAndStop doesn't exists on Lottie type, but it does work.
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                lottie.goToAndStop(totalFrames, true, currentLottieName);
                currentLottie.pause();
            }

            /**
             * Stop the media layer after the end time has exceeded (performance)
             */
            if (play) {
                const delay = (() => {
                    // If the media layer should end later, delay it by the start time.
                    if (TemplateVersionHelpers.shouldMediaLayersEndLater()) {
                        return (endTime - currentTime + startTime) * 1000;
                    } else {
                        return (endTime - currentTime) * 1000;
                    }
                })();

                this.lottieTimeouts[media.id + '_end'] = setTimeout(() => {
                    const newCurrentTime = endTime - 0.1;
                    // ? goToAndStop doesn't exists on Lottie type, but it does work.
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    lottie.goToAndStop(newCurrentTime * 1000, false, currentLottieName);
                    currentLottie.pause();
                }, delay);
            }
        });
    };

    /**
     * Scroll to a specific format.
     * @param format - The format to scroll to.
     */
    static scrollToFormat = (format: Format): void => {
        const x = (format.x ?? 0) - 12;
        const y = (format.y ?? 0) - 12;

        const canvas = InfiniteViewerHelpers.getInfiniteViewer();

        if (canvas) {
            const canvasWidth = canvas.containerElement.getBoundingClientRect().width;
            const canvasHeight = canvas.containerElement.getBoundingClientRect().height;

            const widthRatio = format.width / canvasWidth;
            const heightRatio = format.height / canvasHeight;

            const maxRatio = Math.max(widthRatio, heightRatio);
            let zoomLevel = (1 / maxRatio) * 0.7;

            if (zoomLevel > MAX_ZOOM) {
                zoomLevel = MAX_ZOOM;
            }

            InfiniteViewerHelpers.setZoomLevel(zoomLevel);
        }

        InfiniteViewerHelpers.scrollTo(x, y);
    };

    /**
     * Check which format to select based on the overwrites.
     * @param hasOverwrites - If the format has overwrites.
     * @param formatKey - The format key.
     * @returns The format key to select.
     */
    static formatToSelect = (hasOverwrites: boolean, formatKey?: Format['key'] | null): Format['key'] => {
        if (!formatKey) return 'general';

        const selectedFormats = getTemplateData<Template['state']['selectedFormats']>('state.selectedFormats');

        if (hasOverwrites) {
            // If the selected format is general and it has overwrites, then select the format.
            if (selectedFormats[0] === 'general') {
                return formatKey;
            }

            // If the selected format is not general and it has overwrites, then select the format.
            if (selectedFormats[0] !== 'general') {
                if (selectedFormats[0] !== formatKey) {
                    return formatKey;
                }
            }
        }

        if (selectedFormats[0] !== formatKey) {
            return 'general';
        }

        if (selectedFormats[0] !== 'general') {
            return formatKey;
        }

        if (selectedFormats[0] === 'general') {
            return 'general';
        }

        return formatKey;
    };

    /**
     * Correct a number for the zoom level.
     * @param number Any number
     * @returns The number corrected for zoom
     */
    static scaleValueWithZoom = (number: number): number => {
        const zoom = InfiniteViewerHelpers.getZoomLevel();
        const zoomModifier = 1 / zoom;

        return number * zoomModifier;
    };

    /**
     * Get the maximum canvas size based on the width and height.
     * @param width Width of canvas
     * @param height Height of canvas
     * @returns Object fo new width and height
     */
    static getMaxCanvasSize = (width: number, height: number): { width: number; height: number; sizeCorrection: number; scaleCorrection: number } => {
        const MAX_SQUARE_PIXELS = 15000000;

        const newCanvasSize = {
            width: width * this.CANVAS_SCALE,
            height: height * this.CANVAS_SCALE,
            sizeCorrection: 1,
            scaleCorrection: this.CANVAS_SCALE
        };
        const squarePixels = newCanvasSize.width * newCanvasSize.height;

        if (squarePixels > MAX_SQUARE_PIXELS) {
            const oldWidth = newCanvasSize.width;
            // Calculate the ratio of the original width and height
            const ratio = width / height;

            // Calculate the new width and height
            const newWidth = Math.floor(Math.sqrt(MAX_SQUARE_PIXELS * ratio));
            const newHeight = Math.floor(MAX_SQUARE_PIXELS / newWidth);

            newCanvasSize.width = newWidth;
            newCanvasSize.height = newHeight;

            const newSizeCorrection = newWidth / oldWidth;
            newCanvasSize.sizeCorrection = newSizeCorrection;
            newCanvasSize.scaleCorrection = this.CANVAS_SCALE * newSizeCorrection;

            return newCanvasSize;
        }

        return newCanvasSize;
    };
}

export { CanvasHelpers };
