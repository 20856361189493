import set from 'lodash/set';
import get from 'lodash/get';
import merge from 'lodash/merge';
import unset from 'lodash/unset';
import isEqual from 'lodash/isEqual';
import ImageFileService from 'services/image-file/image.service';
import uploadFile from 'services/upload-file/uploadFile.service';
import Translation from 'components/data/Translation';
import { Clipboard } from 'helpers/clipboard.helpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import LWFiles from 'components/data/Files';
import canvasActive from '../components/canvas/utils/canvasActive';
import Layer, { LayerToAdd } from '../types/layer.type';
import { isTextSelected } from '../utils/isTextSelected';
import LayerHelpers from './layer.helpers';
import { getTemplateData } from './data.helpers';
import Template, { RightSidebarTab, State, TemplateData, View } from '../types/template.type';
import { AnimationCopy, LayerCopy, LayerStylingCopy } from '../types/clipboard.type';
import cloneDeep from '../utils/cloneDeep';
import TemplateDesignerStore, { MultiModel } from '../data/template-designer-store';
import { TemplateVersionHelpers } from './template-version.helpers';
import { FontHelpers } from './font.helpers';
import LayerProperties, { CustomAndPredefinedAnimations, TextProperties, Visibility } from '../types/layerProperties.type';
import { generateKey } from '../utils/generateKey';
import { isAnimationCopy, isLayerCopy, isLayerStylingCopy } from '../utils/typeGuards';
import { LayerPropertiesHelpers } from './layer-properties.helpers';
import { defaultLayerProperties } from '../config/layer-properties/default-layer-properties';
import Src from '../types/src.type';
import { StylingHelpers } from './styling.helpers';
import TemplateDesignerService from '../services/template-designer.service';
import FrameTypeHelpers from './frame-type.helpers';
import FormatHelpers from './format.helpers';
import Animation, { ActiveAnimation, ActiveAnimationType, AnimationOptions, PredefinedAnimationValue } from '../types/animation.type';
import { TimelineHelpers } from './timeline.helpers';
import { roundToNearestDecimal } from '../utils/roundNumbers';
import { ConfigHelpers } from './config.helpers';
import { DynamicLayerInput } from '../types/dynamicLayer.type';
import { ANIMATION_LAYER_TYPES } from '../constants';

/**
 * Class that contains helper functions for copying and pasting.
 */
class CopyPasteHelpers {
    /**
     * Copy layer by getting the styling and store it in the clipboard.
     * @param layerKey - The layer to copy.
     */
    static async copyLayer(layerKey: Layer['key']): Promise<void> {
        if (!canvasActive()) return;
        if (isTextSelected()) return;

        const layer = LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const feedMapping = getTemplateData<Template['dataVariables']>('dataVariables');
        const frameType = getTemplateData<Template['view']['frameType']>('view.frameType');
        const templateVersion = getTemplateData<Template['templateSetup']['templateVersion']>('templateSetup.templateVersion');
        const selectedFormats = getTemplateData<Template['state']['selectedFormats']>('state.selectedFormats');
        const selectedFormat = selectedFormats[0];
        const frameDuration = FrameTypeHelpers.getFrameDuration(frameType);

        const layerCopyData: LayerCopy = {
            copyEvent: 'layerCopy',
            layers: [layer],
            templateVersion,
            frameDuration,
            layerProperties: {} as LayerCopy['layerProperties'],
            dynamicLayers: [],
            feedMapping: {}
        };

        /**
         * Recursive function that generates the copies for the layer properties.
         * @param layer - The layer to generate the copies for.
         */
        function generateLayerCopies(layer: Layer): void {
            // Only copy the layer properties for the selected format.
            if (selectedFormat !== 'general') {
                const { layerProps } = LayerPropertiesHelpers.mergeLayerProperties(layer.key, frameType, [selectedFormat], layerProperties);
                if (!layerProps) return;
                set(layerCopyData.layerProperties, `general.${layer.key}`, layerProps);
            } else {
                // Copy the layer properties for all formats.
                Object.keys(layerProperties).forEach((format) => {
                    const formatProperties = get(layerProperties, `${format}.${frameType}.${layer.key}`);
                    if (!formatProperties) return;
                    set(layerCopyData.layerProperties, `${format}.${layer.key}`, cloneDeep(formatProperties));
                });
            }

            /**
             * Recursive function that gets all dynamic layer inputs for the layer.
             * @param dynamicLayerInputs - The dynamic layer inputs to get.
             */
            function getAllDynamicLayerInputs(dynamicLayerInputs: DynamicLayerInput[]) {
                dynamicLayerInputs.forEach((dynamicLayerInput) => {
                    if ('layerKey' in dynamicLayerInput && dynamicLayerInput.layerKey === layer.key) {
                        /**
                         * Removing conditions that are of type field because they are connected to the layer that is not copied.
                         */
                        const newDynamicLayerInput = (() => {
                            const input: DynamicLayerInput = cloneDeep(dynamicLayerInput);

                            // If it has a condition which is not custom.
                            if (input.condition && typeof input.condition !== 'string') {
                                input.condition = input.condition.map((subArray) => subArray.filter((item) => item.type !== 'field'));
                            }

                            return input;
                        })();

                        if ('children' in newDynamicLayerInput && newDynamicLayerInput.children.length > 0) {
                            newDynamicLayerInput.children = [];
                        }

                        layerCopyData.dynamicLayers.push(newDynamicLayerInput);
                    }

                    if ('children' in dynamicLayerInput && dynamicLayerInput.children.length > 0) {
                        getAllDynamicLayerInputs(dynamicLayerInput.children);
                    }
                });
            }

            // Get all dynamic layers inputs for the layer.
            getAllDynamicLayerInputs(dynamicLayers[frameType]);

            // Get all feed mappings for the layer.
            if (feedMapping && feedMapping[frameType]?.[layer.key]) {
                layerCopyData.feedMapping[layer.key] = feedMapping[frameType][layer.key];
            }

            if (layer.children && layer.children.length > 0) {
                layer.children.forEach(generateLayerCopies);
            }
        }

        generateLayerCopies(layer);

        await Clipboard.write<LayerCopy>(layerCopyData);
    }

    /**
     * Copy the animations of the selected layers and store it in the clipboard.
     * If there is only one layer selected, the whole layer is copied.
     * @param activeAnimations - The active animations to copy.
     */
    static async copyAnimations(activeAnimations?: State['activeAnimations']): Promise<void> {
        if (!canvasActive()) return;

        const templateType = getTemplateData<TemplateData['type']>('templateData.type');
        if (['dynamicImageDesigned', 'dynamicPDFDesigned'].includes(templateType)) return;

        if (activeAnimations === undefined) activeAnimations = getTemplateData<Template['state']['activeAnimations']>('state.activeAnimations');

        if (activeAnimations.length === 1 && activeAnimations[0].type === ActiveAnimationType.Visibility) {
            return this.copyLayer(activeAnimations[0].layer);
        }

        /**
         * Check if there are more then 1 layer in active animations.
         * If there are more then 1 layer, show a snackbar message that multiple layers are selected and can't be copied.
         */
        const totalLayersSelected = activeAnimations.reduce(
            (total, animation) => {
                if (!total.includes(animation.layer)) {
                    total.push(animation.layer);
                }
                return total;
            },
            [] as Layer['key'][]
        ).length;

        if (totalLayersSelected > 1) {
            return SnackbarUtils.info(Translation.get('general.messages.multipleLayersKeyframeCopy', 'template-designer'));
        }

        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const currentFormat = FormatHelpers.currentFormat();
        const selectedLayer = getTemplateData<State['selectedLayers']>('state.selectedLayers')[0];

        const { layerProps } = LayerPropertiesHelpers.mergeLayerProperties(selectedLayer.key, frameType, [currentFormat]);

        const animationCopy: AnimationCopy = {
            copyEvent: 'animationCopy',
            animations: {}
        };

        let unableToCopy = false;

        /**
         * Go over each active animation and copy the keyframes depending on the type of animation
         */
        activeAnimations.forEach((animation) => {
            const key = animation.animationKey ?? '';

            if (!animationCopy.animations[key]) {
                animationCopy.animations[key] = [];
            }

            switch (animation.type) {
                case ActiveAnimationType.PredefinedAnimation: {
                    if (animationCopy.animations[key].length > 0) return;

                    const keyframes = TimelineHelpers.getKeyframesByActiveAnimation(animation);
                    if (!keyframes) {
                        unableToCopy = true;
                        return;
                    }

                    animationCopy.animations[key].push(...keyframes);
                    break;
                }
                case ActiveAnimationType.Keyframe: {
                    const keyframe = (get(layerProps, `animations.${key}`, []) as Animation[]).find((keyframe) => keyframe.id === animation.keyframeKey);
                    if (!keyframe) return;

                    animationCopy.animations[key].push(keyframe);
                    break;
                }
            }
        });

        Object.keys(animationCopy.animations).forEach((animationKey) => {
            const animation = animationCopy.animations[animationKey];
            TimelineHelpers.sortKeyframes(animation);
        });

        if (unableToCopy) {
            SnackbarUtils.error(Translation.get('general.errors.unableToCopyKeyframes', 'template-designer'));
        }

        await Clipboard.write<AnimationCopy>(animationCopy);
    }

    /**
     * Check if the clipboard has data that can be pasted.
     */
    static async paste(): Promise<void> {
        const copiedData = await Clipboard.read<LayerCopy | File | AnimationCopy>();
        if (!copiedData) return;

        const isFileCopyData = copiedData instanceof File;
        const isLayerCopyData = isLayerCopy(copiedData as LayerCopy);
        const isAnimationCopyData = isAnimationCopy(copiedData as AnimationCopy);

        // Check if the copied data is a file.
        if (isFileCopyData) {
            this.pasteImage(copiedData);
        }

        if (isLayerCopyData) {
            this.pasteLayer(copiedData as LayerCopy);
        }

        if (isAnimationCopyData) {
            this.pasteAnimations(copiedData as AnimationCopy);
        }
    }

    /**
     * Paste layer from clipboard.
     */
    static pasteLayer(layerCopy: LayerCopy, selectPastedLayer = true): void {
        if (!canvasActive()) return;
        if (isTextSelected()) return;

        const formats = getTemplateData<Template['formats']>('formats');
        if (!formats || formats.length === 0) return;

        const templateType = getTemplateData<Template['templateData']['type']>('templateData.type');
        const templateVersion = getTemplateData<Template['templateSetup']['templateVersion']>('templateSetup.templateVersion');
        const layers = getTemplateData<Template['layers']>('layers');
        const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');
        const dynamicLayers = getTemplateData<Template['dynamicLayers']>('dynamicLayers');
        const feedMapping = getTemplateData<Template['dataVariables']>('dataVariables');
        const frameType = getTemplateData<Template['view']['frameType']>('view.frameType');
        const selectedLayer = getTemplateData<Template['state']['selectedLayers']>('state.selectedLayers')[0];
        const frameDuration = FrameTypeHelpers.getFrameDuration(frameType);

        const hasAnimations = ConfigHelpers.hasAnimations(templateType);

        /**
         * Recursive function that checks if the layer or its children have a layer of the given type.
         * @param layer - The layer to check.
         * @param type - The type of the layer to check for.
         * @returns True if the layer or its children have a layer of the given type.
         */
        function checkForTypeLayer(layer: Layer) {
            if (ANIMATION_LAYER_TYPES.includes(layer.type)) {
                return true;
            }

            if (layer.children && layer.children.length > 0) {
                for (const childLayer of layer.children) {
                    return checkForTypeLayer(childLayer);
                }
            }
        }

        if (!hasAnimations && checkForTypeLayer(layerCopy.layers[0])) {
            return SnackbarUtils.error(Translation.get('general.errors.layerNotAllowed', 'template-designer'));
        }

        const {
            templateVersion: copiedTemplateVersion,
            layerProperties: copiedLayerProperties,
            dynamicLayers: copiedDynamicLayers,
            feedMapping: copiedFeedMapping,
            frameDuration: copiedFrameDuration
        } = layerCopy;

        /**
         * If the old duration is longer then the new duration, the animations are shortend.
         * This is used to show a snackbar message to the user.
         */
        let animationsShortend = false;

        /**
         * Recursive function that copies the layer and its children.
         * @param layerCopy - The layer to copy.
         * @param firstLayer - If the layer is the first layer.
         * @returns The copied layer.
         */
        function prepareLayer(layer: LayerCopy['layers'][0], firstLayer?: boolean) {
            const newLayerKey = generateKey(layer.type);

            const newLayer: Layer = {
                ...layer,
                key: newLayerKey,
                children:
                    layer.children && layer.children.length > 0
                        ? layer.children.map((childLayer) => {
                              return prepareLayer(childLayer, false);
                          })
                        : []
            };

            if (firstLayer) {
                newLayer.title = LayerHelpers.generateLayerTitle(newLayer, layers[frameType]);
            }

            Object.keys(copiedLayerProperties).forEach((format) => {
                const formatToUse = (() => {
                    if (format === 'general') return format;
                    return formats.find((formatData) => formatData.key === format)?.key;
                })();

                if (!formatToUse) return;

                const newLayerProperties: LayerProperties = cloneDeep(get(copiedLayerProperties, `${format}.${layer.key}`));
                if (!newLayerProperties || Object.keys(newLayerProperties).length === 0 || Array.isArray(newLayerProperties)) return;

                /**
                 * Adjust animation an visibility based on the copied frame duration and the current frame duration so animations will never fall out of the timeline.
                 * We only need to do this when the old frame duration (copied frame duration) is shorter then the new frame duration.
                 * Because then then the animation stay the same length.
                 */
                if (copiedFrameDuration && frameDuration && copiedFrameDuration < frameDuration) {
                    const visibility = newLayerProperties.visibility;

                    // Adjust visibility.
                    if (visibility !== undefined) {
                        newLayerProperties.visibility = visibility.map((stamp) => {
                            const oldStampTime = TimelineHelpers.stampToSeconds(stamp, copiedFrameDuration);
                            const newStampTime = TimelineHelpers.secondsToStamp(oldStampTime, frameDuration);
                            return Math.round(newStampTime * 100) / 100;
                        }) as Visibility;
                    }

                    // Adjust timestamps.
                    newLayerProperties.animations &&
                        Object.keys(newLayerProperties.animations).forEach((animationKey) => {
                            let animations = newLayerProperties?.animations?.[animationKey];

                            if (animations) {
                                animations.forEach((keyframe) => {
                                    const oldKeyframeTime = TimelineHelpers.stampToSeconds(keyframe.stamp, copiedFrameDuration);
                                    const newKeyframeStamp = TimelineHelpers.secondsToStamp(oldKeyframeTime, frameDuration);
                                    keyframe.stamp = newKeyframeStamp;
                                });

                                animations = TimelineHelpers.checkSameTimestamp(animations);
                            }
                        });
                } else if (copiedFrameDuration && frameDuration && copiedFrameDuration > frameDuration) {
                    animationsShortend = true;

                    newLayerProperties.animations &&
                        Object.keys(newLayerProperties.animations).forEach((animationKey) => {
                            const animations = newLayerProperties?.animations?.[animationKey];

                            if (animations) {
                                // Check if the difference between the keyframes are enough.
                                animations.forEach((keyframe, index, array) => {
                                    if (index === 0) return;

                                    const currentKeyframeTime = TimelineHelpers.stampToSeconds(keyframe.stamp, copiedFrameDuration);
                                    const previousKeyframeTime = TimelineHelpers.stampToSeconds(array[index - 1].stamp, copiedFrameDuration);

                                    if (previousKeyframeTime > currentKeyframeTime) {
                                        keyframe.stamp = array[index - 1].stamp + 0.01;
                                    } else if (currentKeyframeTime - previousKeyframeTime <= 0.1) {
                                        keyframe.stamp += 0.01;
                                    }
                                });
                            }
                        });
                }

                /**
                 * If the copied layer is of type text. Check what the font source is.
                 * If it is a Google font or brand guide font, we need to load it in.
                 */
                if (newLayer.type === 'text') {
                    const textProperties = newLayerProperties.properties as TextProperties;

                    if (textProperties.textStyling?.normal?.fontSource === 'googleFonts') {
                        FontHelpers.loadGoogleFonts([textProperties.textStyling.normal.fontFamily]);
                    }

                    if (textProperties.textStyling?.highlighted?.fontSource === 'googleFonts') {
                        FontHelpers.loadGoogleFonts([textProperties.textStyling.highlighted.fontFamily]);
                    }

                    if (textProperties.textStyling?.normal?.fontSource?.includes('brandGuide')) {
                        const brandGuideFonts = FontHelpers.getBrandGuideFontsFromFontFamilies([textProperties.textStyling.normal.fontFamily]);
                        FontHelpers.loadBrandGuideFonts(brandGuideFonts);
                    }

                    if (textProperties.textStyling?.highlighted?.fontSource?.includes('brandGuide')) {
                        const brandGuideFonts = FontHelpers.getBrandGuideFontsFromFontFamilies([textProperties.textStyling.highlighted.fontFamily]);
                        FontHelpers.loadBrandGuideFonts(brandGuideFonts);
                    }
                }

                // Remove animations when the template type is dynamicImageDesigned or dynamicPDFDesigned.
                if (['dynamicImageDesigned', 'dynamicPDFDesigned'].includes(templateType)) {
                    newLayerProperties.animations = {};
                    newLayerProperties.visibility = [0, 1];
                }

                set(layerProperties, `${formatToUse}.${frameType}.${newLayerKey}`, newLayerProperties);
            });

            copiedDynamicLayers.forEach((dynamicLayer) => {
                if (!('layerKey' in dynamicLayer) || layer.key !== dynamicLayer.layerKey) return;

                const newKey = generateKey();
                dynamicLayer.key = newKey;
                dynamicLayer.layerKey = newLayerKey;
                dynamicLayers[frameType].push(dynamicLayer);
            });

            if (copiedFeedMapping && copiedFeedMapping[layer.key]) {
                feedMapping[frameType][newLayerKey] = copiedFeedMapping[layer.key];
            }

            return newLayer;
        }

        const newLayers = layerCopy.layers.map((layer) => prepareLayer(layer, true));

        const changes: MultiModel = [
            ['layers', layers],
            ['layerProperties', layerProperties],
            ['dynamicLayers', dynamicLayers],
            ['dataVariables', feedMapping]
        ];

        // Check if the new layers should be reversed.
        const shouldLayerReverseBefore = TemplateVersionHelpers.layersShouldNotReverse(copiedTemplateVersion);
        const shouldLayerReverseAfter = TemplateVersionHelpers.layersShouldNotReverse(templateVersion);

        if (shouldLayerReverseBefore !== shouldLayerReverseAfter) {
            SnackbarUtils.warning(Translation.get('general.warnings.pasteLayerReverse', 'template-designer'), 7500); // 7.5 seconds.
        }

        // If the selected layer is a container and not the same key as the copied layer, add the new layer to the children of the selected layer.
        if (selectedLayer?.type === 'container' && selectedLayer.key !== layerCopy.layers[0].key) {
            let layerAdded = false;

            const addToChildren = (layers: Layer[]) => {
                for (const layer of layers) {
                    if (layer.key === selectedLayer.key && layer.children) {
                        layer.children.push(...newLayers);
                        layerAdded = true;
                        break;
                    }

                    if (layer.children && layer.children.length > 0) {
                        addToChildren(layer.children);
                    }
                }
            };

            addToChildren(layers[frameType]);

            if (!layerAdded) {
                layers[frameType].unshift(...newLayers);
            }
        } else {
            let layerAdded = false;

            const isChildOfContainer = (layers: Layer[]) => {
                for (const layer of layers) {
                    const isChild = !!(layer.children?.length && layer.children.find((child) => child.key === selectedLayer?.key));

                    if (isChild && layer.children) {
                        layer.children.unshift(...newLayers);
                        layerAdded = true;
                        break;
                    }

                    if (layer.children && layer.children.length > 0) {
                        isChildOfContainer(layer.children);
                    }
                }
            };

            isChildOfContainer(layers[frameType]);

            if (!layerAdded) {
                layers[frameType].unshift(...newLayers);
            }
        }

        if (layerCopy.layers.length === 1 && selectPastedLayer) {
            changes.push(['state.selectedLayers', [...newLayers]]);
        }

        if (ConfigHelpers.hasAnimations(templateType) && animationsShortend) {
            SnackbarUtils.info(Translation.get('general.messages.animationsShortend', 'template-designer'));
        }
        if (!ConfigHelpers.hasAnimations(templateType)) {
            SnackbarUtils.info(Translation.get('general.messages.animationsRemoved', 'template-designer'));
        }

        TemplateDesignerStore.save(changes);
    }

    /**
     * Copy the styling of a layer and store it in the clipboard.
     * @param layerKey - The layer to copy the styling from.
     */
    static copyLayerStyling(layerKey: Layer['key']): void {
        if (!canvasActive()) return;
        if (isTextSelected()) return;

        const layer = LayerHelpers.findLayer(layerKey);

        if (!layer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const frameType = getTemplateData<Template['view']['frameType']>('view.frameType');
        const selectedFormats = getTemplateData<Template['state']['selectedFormats']>('state.selectedFormats');
        const selectedFormat = selectedFormats[0];

        const layerCopyData: LayerStylingCopy = {
            copyEvent: 'layerStylingCopy',
            layerType: layer.type,
            layerProperties: {} as LayerStylingCopy['layerProperties']
        };

        const generalProperties = getTemplateData<Template['layerProperties']>(`layerProperties.general.${frameType}.${layerKey}.properties`);
        const defaultProperties = LayerPropertiesHelpers.getDefaultProperties(layer.type);

        if (selectedFormat === 'general') {
            const newLayerProperties = merge(cloneDeep(defaultProperties), generalProperties);
            layerCopyData.layerProperties = newLayerProperties;
        } else {
            const formatProperties = getTemplateData<Template['layerProperties']>(`layerProperties.${selectedFormat}.${frameType}.${layerKey}.properties`);
            const newLayerProperties = merge(defaultProperties, generalProperties, formatProperties);
            layerCopyData.layerProperties = newLayerProperties;
        }

        /**
         * Remove the properties that are not needed for the styling copy.
         */
        const propertiesToDelete = ['x', 'y', 'width', 'height', 'text', 'canEdit'];
        propertiesToDelete.forEach((property) => {
            unset(layerCopyData.layerProperties, property);
        });

        Clipboard.write(layerCopyData);
    }

    /**
     * Paste the styling of a layer from the clipboard.
     * @param targetLayerKey - The layer to paste the styling to.
     */
    static async pasteLayerStyling(targetLayerKey: Layer['key']): Promise<void> {
        if (!canvasActive()) return;

        const copiedData = await Clipboard.read<LayerStylingCopy>();
        if (!copiedData || !isLayerStylingCopy(copiedData) || !copiedData.layerProperties) return;

        const targetLayer = LayerHelpers.findLayer(targetLayerKey);

        if (!targetLayer) {
            throw new Error(Translation.get('general.errors.layerErrors.noLayerFound', 'template-designer'));
        }

        const frameType = getTemplateData<Template['view']['frameType']>('view.frameType');
        const selectedFormats = getTemplateData<Template['state']['selectedFormats']>('state.selectedFormats');
        const selectedFormat = selectedFormats[0];

        /**
         * Filter out properties that are not in the target layer.
         * Ex. If copied layer was a text layer and target is a shape layer. We must filter out the text styling for example.
         */
        if (targetLayer.type !== copiedData.layerType) {
            const targetLayerDefaultProperties = LayerPropertiesHelpers.getDefaultProperties(targetLayer.type);
            if (!targetLayerDefaultProperties) return;

            copiedData.layerProperties = Object.keys(copiedData.layerProperties).reduce(
                (all, key) => {
                    // If the key is media, return all properties. Because video media can't be on image and vice versa.
                    if (key === 'media') {
                        return all;
                    }

                    if (targetLayerDefaultProperties[key]) {
                        all[key] = copiedData.layerProperties[key];
                    }

                    return all;
                },
                {} as LayerProperties['properties']
            );
        }

        if (selectedFormat === 'general') {
            const targetLayerGeneralProperties = getTemplateData<LayerProperties['properties']>(
                `layerProperties.general.${frameType}.${targetLayer.key}.properties`
            );

            const mergedProperties = merge(targetLayerGeneralProperties, copiedData.layerProperties);
            const newLayerProperties = LayerPropertiesHelpers.removeDefaultProperties(
                cloneDeep({ ...defaultLayerProperties, properties: mergedProperties }),
                targetLayer.type
            ).properties;
            TemplateDesignerStore.save([`layerProperties.general.${frameType}.${targetLayer.key}.properties`, newLayerProperties]);
        } else {
            const targetLayerGeneralProperties = getTemplateData<LayerProperties['properties']>(
                `layerProperties.general.${frameType}.${targetLayer.key}.properties`
            );
            const targetLayerFormatProperties =
                getTemplateData<LayerProperties['properties']>(`layerProperties.${selectedFormat}.${frameType}.${targetLayer.key}.properties`) ?? {};
            const mergedProperties = merge(targetLayerFormatProperties, copiedData.layerProperties);
            const cleanLayerProperties = LayerPropertiesHelpers.removeDefaultProperties(
                cloneDeep({ ...defaultLayerProperties, properties: mergedProperties }),
                targetLayer.type
            ).properties;

            /**
             * Check if the copied properties are different from the target general layer properties.
             * If they are the same, filter them out.
             * Otherwise they will be overwrites.
             */
            const newLayerProperties = Object.keys(cleanLayerProperties).reduce(
                (all, key) => {
                    const generalProperty = targetLayerGeneralProperties[key];
                    const overwriteProperty = cleanLayerProperties[key];

                    if (isEqual(generalProperty, overwriteProperty)) {
                        return all;
                    }

                    all[key] = overwriteProperty;
                    return all;
                },
                {} as LayerProperties['properties']
            );

            TemplateDesignerStore.save([`layerProperties.${selectedFormat}.${frameType}.${targetLayer.key}.properties`, newLayerProperties]);
        }

        /**
         * If the copied layer is of type text. Check what the font source is. Load in external fonts.
         */
        if (copiedData.layerType === 'text') {
            const textProperties = copiedData.layerProperties as unknown as TextProperties;

            if (textProperties.textStyling?.normal?.fontSource === 'googleFonts') {
                FontHelpers.loadGoogleFonts([textProperties.textStyling.normal.fontFamily]);
            }

            if (textProperties.textStyling?.highlighted?.fontSource === 'googleFonts') {
                FontHelpers.loadGoogleFonts([textProperties.textStyling.highlighted.fontFamily]);
            }

            if (textProperties.textStyling?.normal?.fontSource?.includes('brandGuide')) {
                const brandGuideFonts = FontHelpers.getBrandGuideFontsFromFontFamilies([textProperties.textStyling.normal.fontFamily]);
                FontHelpers.loadBrandGuideFonts(brandGuideFonts);
            }

            if (textProperties.textStyling?.highlighted?.fontSource?.includes('brandGuide')) {
                const brandGuideFonts = FontHelpers.getBrandGuideFontsFromFontFamilies([textProperties.textStyling.highlighted.fontFamily]);
                FontHelpers.loadBrandGuideFonts(brandGuideFonts);
            }
        }
    }

    /**
     * Paste image from clipboard.
     * @param image - The image to paste.
     */
    static pasteImage = async (image: File): Promise<void> => {
        if (!canvasActive()) return;

        TemplateDesignerStore.save(['state.isUploading', true, false]);

        const signedUrls = await TemplateDesignerService.getSignedUrls(image.name);

        if (!signedUrls) {
            throw new Error(Translation.get('general.errors.noSignedUrls'));
        }

        await uploadFile(signedUrls.uploadUrl, image);

        const imageUrl = signedUrls.downloadUrl;

        if (!imageUrl) {
            throw new Error(Translation.get('general.errors.noDownloadUrl'));
        }

        /**
         * Get the media dimension if the file is an image.
         */
        const imageDimension = await (async (): Promise<{ width: number; height: number } | undefined> => {
            const item = await LWFiles.loadImage(imageUrl);

            const width = item && item.width ? item.width : null;
            const height = item && item.height ? item.height : null;

            return { width, height };
        })();

        const imageSize = await ImageFileService.getImageFileSize(imageUrl);

        const extension = (() => {
            const regex = /\.([0-9a-z]+)(?:[?#]|$)/i;
            const match = imageUrl.match(regex);
            if (match) {
                return match[1];
            } else {
                return null;
            }
        })();

        const itemToAdd: Src = {
            assetGalleryInput: false,
            openAssetEditor: true,
            extension: extension ?? 'jpg',
            fileType: 'image',
            humanSize: LWFiles.humanReadableSize(imageSize ?? 0),
            size: imageSize ?? 0,
            fileName: 'Image from clipboard',
            title: 'Image from clipboard',
            url: imageUrl
        };

        if (imageDimension) {
            itemToAdd.assetData = imageDimension;
        }

        const mediaSize = await StylingHelpers.getMediaSize('image', itemToAdd);

        const changes: LayerToAdd['data'] = [['properties.media.src', itemToAdd]];
        if (mediaSize) {
            changes.push(['properties.width.value', mediaSize.width]);
            changes.push(['properties.height.value', mediaSize.height]);
        }

        LayerHelpers.addLayers([{ layerType: 'image', data: changes }]);

        TemplateDesignerStore.save(['state.isUploading', false, false]);
    };

    /**
     * Paste animations from clipboard.
     * @param animationCopy - The animation to paste.
     */
    static pasteAnimations = (animationCopy: AnimationCopy): void => {
        if (!canvasActive()) return;

        const templateType = getTemplateData<TemplateData['type']>('templateData.type');
        if (['dynamicImageDesigned', 'dynamicPDFDesigned'].includes(templateType)) return;

        const selectedLayer = getTemplateData<State['selectedLayers']>('state.selectedLayers')[0];
        if (!selectedLayer) return;

        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const currentFormat = FormatHelpers.currentFormat();

        let seekTo: number | null = null;

        const changes: MultiModel = [['view.showTab', RightSidebarTab.Animate]];

        let keyframesOverwritten = false;
        let textColorAnimationError = false;

        let newActiveAnimation: ActiveAnimation = null;

        /**
         * Go over each copied animation and paste it to the selected layer.
         */
        Object.keys(animationCopy.animations).forEach((animationKey) => {
            if (selectedLayer.type !== 'text' && (animationKey as AnimationOptions) === 'color') {
                textColorAnimationError = true;
                return;
            }

            const isPredefinedAnimation = TimelineHelpers.isPredefinedAnimation(animationKey as keyof CustomAndPredefinedAnimations);
            const animation = animationCopy.animations[animationKey] as Animation[];
            let newAnimation = cloneDeep(animation);

            const stampToSeconds = TimelineHelpers.stampToSeconds(newAnimation[0].stamp);

            if (seekTo === null || seekTo > stampToSeconds) {
                seekTo = stampToSeconds;
            }

            newAnimation = newAnimation.map((keyframe) => {
                keyframe.id = generateKey();
                keyframe.stamp = roundToNearestDecimal(keyframe.stamp, 500);

                return keyframe;
            });

            const model = `layerProperties.${currentFormat}.${frameType}.${selectedLayer.key}.animations.${animationKey}`;

            const currentAnimation = getTemplateData<Animation[]>(model);

            /**
             * If the animation is a predefined animation, we need to check if the animation already exists.
             * If it does, we need to create a new index for the animation.
             */
            if (isPredefinedAnimation && currentAnimation?.length > 0) {
                const predefinedAnimation = currentAnimation as PredefinedAnimationValue[];
                const animationType = predefinedAnimation[0].type;

                const mergedLayerProperties = LayerPropertiesHelpers.mergeLayerProperties(selectedLayer.key, frameType, [currentFormat]);
                const newIndex = TimelineHelpers.getNewPredefinedAnimationIndex(mergedLayerProperties.layerProps, animationType);

                const newAnimationKey = generateKey(animationKey);
                const newAnimation = predefinedAnimation.map((keyframe) => ({
                    ...keyframe,
                    id: generateKey(),
                    index: newIndex
                }));

                if (!newActiveAnimation) {
                    newActiveAnimation = {
                        type: ActiveAnimationType.Keyframe,
                        layer: selectedLayer.key,
                        animationKey: newAnimationKey as keyof CustomAndPredefinedAnimations,
                        keyframeKey: newAnimation[0].id
                    };
                }

                changes.push([`layerProperties.${currentFormat}.${frameType}.${selectedLayer.key}.animations.${newAnimationKey}`, newAnimation]);
                return;
            }

            /**
             * If the animation does not exist, we can just add the new animation.
             */
            if (!currentAnimation || currentAnimation.length === 0 || isPredefinedAnimation) {
                if (!newActiveAnimation) {
                    newActiveAnimation = {
                        type: ActiveAnimationType.Keyframe,
                        layer: selectedLayer.key,
                        animationKey: animationKey as keyof CustomAndPredefinedAnimations,
                        keyframeKey: newAnimation[0].id
                    };
                }
                changes.push([`layerProperties.${currentFormat}.${frameType}.${selectedLayer.key}.animations.${animationKey}`, newAnimation]);
                return;
            }

            currentAnimation.push(...newAnimation);

            /**
             * Remove duplicate keyframes.
             * We want to remove the first keyframe because the last keyframe should overwrite the first keyframe.
             */
            currentAnimation.reverse();
            const stampSet = new Set();
            const uniqueAnimation = currentAnimation.filter((keyframe) => {
                if (stampSet.has(keyframe.stamp)) {
                    keyframesOverwritten = true;
                    return false;
                }

                stampSet.add(keyframe.stamp);
                return true;
            });

            TimelineHelpers.sortKeyframes(uniqueAnimation);

            if (!newActiveAnimation) {
                newActiveAnimation = {
                    type: ActiveAnimationType.Keyframe,
                    layer: selectedLayer.key,
                    animationKey: animationKey as keyof CustomAndPredefinedAnimations,
                    keyframeKey: uniqueAnimation[0].id
                };
            }

            changes.push([`layerProperties.${currentFormat}.${frameType}.${selectedLayer.key}.animations.${animationKey}`, uniqueAnimation]);
        });

        if (seekTo !== null) {
            TimelineHelpers.seekTo(seekTo);
        }

        changes.push(['state.activeAnimations', [newActiveAnimation]]);

        TemplateDesignerStore.save(changes);

        if (keyframesOverwritten) {
            SnackbarUtils.warning(Translation.get('general.warnings.keyframesOverwritten', 'template-designer'));
        }

        if (textColorAnimationError) {
            SnackbarUtils.error(Translation.get('general.warnings.textColorAnimationError', 'template-designer'));
        }
    };
}

export { CopyPasteHelpers };
