import ComponentStore from 'components/data/ComponentStore';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { TemplateDesignerHelpers } from 'components/template-designer/helpers/template-designer.helpers';
import { History } from 'helpers/history.helpers';

export type SingleModel = [string, unknown, boolean?];
export type MultiModel = SingleModel[];

export interface TemplateDesignerStoreProps {
    models: SingleModel | MultiModel;
    options: { saveToHistory?: boolean };
}

/**
 * Helpers for saving Template Designer data to the store and history.
 */
class TemplateDesignerStore {
    static TEMPLATE_DESIGNER_MAX_HISTORY_LENGTH = 20;

    /**
     * Save the models to the store.
     * @param models - The models to save to the store.
     * @param options - Options for the save.
     * @param options.saveToHistory - Whether to save the models to history.
     */
    static save = (models: TemplateDesignerStoreProps['models'], options?: TemplateDesignerStoreProps['options']): void => {
        const { saveToHistory = true } = options || {};

        if (!Array.isArray(models[0])) {
            models = [models] as MultiModel;
        } else {
            models = models as MultiModel;
        }

        if (saveToHistory) {
            // Set the flag for changed after save to false so the user gets a warning if they try to leave the page.
            models.push(['state.changedAfterSave', true]);
            this.saveToHistory(models);
        }

        ComponentStore.setMultiModels('TemplateDesigner', models);
    };

    /**
     * Get the data from the store with fallback.
     * @param models The models to get if the first one exists it returns that data otherwise it returns the second one etc..
     * @returns The data of the model
     */
    static getModelWithFallback = <Data>(models: string[]): Data => {
        for (const model of models) {
            const data = getTemplateData<Data>(model);
            if (data !== undefined) return data;
        }
        return undefined as Data;
    };

    /**
     * Save the models to history.
     * @param models - The models to save to history.
     */
    private static saveToHistory = (models: MultiModel): void => {
        // Save the data of the models as they are before the changes about to happen.
        const modelsToSave: MultiModel = models
            .filter((model) => model[2] !== false)
            .map((model) => [model[0], ComponentStore.getItem('TemplateDesigner', model[0] as string)]);

        // Save the state and frame type in history to better show what changed.
        modelsToSave.push(['view.frameType', ComponentStore.getItem('TemplateDesigner', 'view.frameType')]);

        // Save the models to history.
        History.save(modelsToSave, 'TemplateDesigner', TemplateDesignerHelpers.getCurrentView(), this.TEMPLATE_DESIGNER_MAX_HISTORY_LENGTH);
    };
}

export default TemplateDesignerStore;
